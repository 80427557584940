import React from 'react'

export default function Contact() {
  return (
    <div className='cont' style={{width:'80%',margin:'0 auto',marginTop:'30px',marginBottom:'50px',border:'1px solid black',height: "350px"}} >
    <p style={{marginTop:'50px',fontSize:'250%',fontFamily: "Times New Roman"}}> צור קשר</p>
    <p style={{fontSize:'130%',marginTop:'30px',marginBottom:'0px'}}>:כתובת מייל</p>
    <p style={{fontSize:'110%',marginTop:'00px'}}> y.d.handmade.jewelry@gmail.com</p>
    <p style={{fontSize:'130%',marginBottom:'5px'}}> :מספר טלפון  </p>
    <p style={{fontSize:'110%',marginTop:'5px'}}> 0528775898 <br /> 0527899444 </p>
  
  </div>
  )
}
